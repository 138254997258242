let defaultTheme = {
  containerStyle: {
    overflow: void 0,
    position: 'absolute',
    left: 0,
    top: 0,
    right: 0,
    bottom: 0,
    alignItems: 'center',
    justifyContent: 'center',
    zIndex: 11,
  },
  animationStyle: {
    width: 40,
    height: 40,
    borderWidth: 4,
    borderColor: 'green',
    borderRightColor: 'transparent',
    borderRadius: '50%',
    animationName: 'spinnerRotate',
    animationDuration: '0.75s',
    animationIterationCount: 'infinite',
    animationTimingFunction: 'linear',
  },
};
export {defaultTheme};
