import { getResolvedMQProps } from "../../BreakPoints";

export const getResolvedProps = (props, {navigation} = {}) => {
  if (!props) {
    return props;
  }
  if (!navigation) {
    navigation = props.navigation;
  }
  const activeMQ =
    navigation && navigation.getActiveMQ && navigation.getActiveMQ();
  return getResolvedMQProps(props, {activeMQ}) || {};
};

export const getResolvedGroups = (groups, props) => {
  if (!groups || !Array.isArray(groups) || !groups.length) {
    return groups;
  }
  let newGroups = [];
  for (let group of groups) {
    if (!group) {
      continue;
    }
    if (
      props.editable !== false &&
      props.activeMQ === 'sm' &&
      group[props.activeMQ] === undefined
    ) {
      group = {
        ...group,
        [props.activeMQ]: {
          direction: void 0,
          columnsPerRow: 1,
        },
      };
    }

    let {activeMQ, ...resolvedGroup} = getResolvedProps(group, props);
    if (!resolvedGroup || !Object.keys(resolvedGroup).length) {
      continue;
    }
    if (resolvedGroup.groups) {
      let resolvedGroups = getResolvedGroups(resolvedGroup.groups, props);
      if (
        resolvedGroups &&
        Array.isArray(resolvedGroups) &&
        resolvedGroups.length
      ) {
        resolvedGroup.groups = resolvedGroups;
      } else {
        delete resolvedGroup.groups;
      }
      if (!Object.keys(resolvedGroup).length) {
        continue;
      }
    }
    newGroups.push(resolvedGroup);
  }
  return newGroups;
};
