import {manazeV6Colors} from './manazeV6Colors';
import {Headline14} from './Headlines';

const {
  neutral_4,
  neutral_1,
  surface_white,
  positive_communication_4,
  primary_5,
} = manazeV6Colors;

export const progressSliderTheme = {
  containerStyle: {
    height: 44,
    paddingTop: 8,
    justifyContent: 'flex-end',
  },
  titleStyle: {
    numberOfLines: 1,
    ...Headline14,
    color: neutral_4,
    paddingBottom: 5,
  },
  valueStyle: {
    minWidth: 50,
    textAlign: 'right',
    ...Headline14,
    color: neutral_1,
  },
  color: primary_5,
  activeColor: positive_communication_4,
  thumbColor: surface_white,
};
