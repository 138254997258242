import React from 'react';
import Standard from './Standard';
import Filled from './Filled';
import Outlined from './Outlined';
import {getRenderComponent} from './Utility';

export default Component => {
  const variants = {
    standard: Standard,
    filled: Filled,
    outlined: Outlined,
  };

  class InputVariant extends React.Component {
    render() {
      let {variant = 'standard'} = this.props;
      let Variant = variant;
      if (typeof variant === 'string') {
        Variant = variants[variant] || variants['standard'];
      }
      if (!Variant) {
        return <Component {...this.props} />;
      }
      return getRenderComponent(Variant, {
        ...this.props,
        Component,
      });
    }
  }

  return InputVariant;
};
