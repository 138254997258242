import React from 'react';
import ScrollView from './ScrollView';
import View from './View';

class FlatList extends React.Component {
  render() {
    const {
      data = [],
      numColumns,
      columnWrapperStyle,
      renderItem,
      ...restProps
    } = this.props;
    if (!renderItem) {
      return null;
    }
    const renderComponent = numColumns ? (
      <View style={{flexDirection: 'row', flexWrap: 'wrap'}}>
        {data.map((item, index) => {
          return (
            <View style={{width: `${100 / numColumns}%`}}>
              {renderItem({item, index})}
            </View>
          );
        })}
      </View>
    ) : (
      data.map((item, index) => {
        return renderItem({item, index});
      })
    );

    return <ScrollView {...restProps}>{renderComponent}</ScrollView>;
  }
}

export default FlatList;
