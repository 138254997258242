import React from 'react';
import uuid from 'uuid/v4';
import FilterBar from './FilterBar';

export const Filter = props => {
  return <FilterBar {...props} />;
};

export const GroupFilter = props => ({
  key: uuid(),
  type: 'groupFilter',
  isActive: ({navigation, action}) => {
    let {getScreenState /* , getTabScreenState */} = navigation || {};
    if (!getScreenState /* && !getTabScreenState */) {
      return;
    }
    let {key} = action;
    let {dataParams: {filters} = {}, filterActionKey} =
      (getScreenState && getScreenState()) || {};
    // let {dataParams: {filters: tabFilters} = {}} =
    // (getTabScreenState && getTabScreenState()) || {};
    if (filterActionKey === key) {
      return true;
    }
    if (!filters /* && !tabFilters */) {
      return;
    }
    if (action.filters) {
      for (let filterInfo of action.filters) {
        if (filterInfo && typeof filterInfo === 'object') {
          const {field} = filterInfo;
          if (field) {
            if (
              filters &&
              filters[field] &&
              filters[field].value !== undefined &&
              filters[field].value !==
                null /* ||
              (tabFilters &&
                tabFilters[field] &&
                tabFilters[field].value !== undefined &&
                tabFilters[field].value !== null) */
            ) {
              return true;
            }
          }
        }
      }
    }
  },
  onUnmount: ({navigation, action}) => {
    let {getScreenState, setScreenState} = navigation || {};
    if (!getScreenState && setScreenState) {
      return;
    }
    const {key} = action || {};
    let {filterActionKey} = getScreenState() || {};
    if (filterActionKey === key) {
      setScreenState({
        filterAction: void 0,
        filterActionKey: void 0,
      });
    }
  },
  ...props,
});

export const GroupFilterAction = ({navigation, action} = {}) => {
  if (!navigation) {
    return;
  }
  let {setScreenState, getScreenState} = navigation;
  const {filters, key} = action || {};
  if (!filters || !filters.length || !setScreenState || !getScreenState) {
    return;
  }
  let {filterActionKey} = getScreenState() || {};
  if (filterActionKey === key) {
    setScreenState({
      filterAction: void 0,
      filterActionKey: void 0,
    });
  } else {
    setScreenState({
      filterAction: action,
      filterActionKey: key,
    });
  }
};
