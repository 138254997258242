/**
 * todo ref should not always be there, add in case of layout - rohit bansal - 04-12-18
 *
 */
import React from 'react';
import StyleSheet from './StyleSheet';
const defaultStyle = {
  alignItems: 'stretch',
  borderWidth: 0,
  borderStyle: 'solid',
  boxSizing: 'border-box',
  display: 'flex',
  flexDirection: 'column',
  margin: 0,
  padding: 0,
  flexShrink: 0,
  position: 'relative',
};

const removeExtraDomProps = (props = {}) => {
  let {
    listKey,
    extraData,
    keyExtractor,
    fetchMore,
    activeOpacity,
    stopPropagation,
    disabled,
    ...restProps
  } = props;
  return restProps;
};

class View extends React.Component {
  measure = callback => {
    if (this.viewRef) {
      let {
        x,
        y,
        top,
        left,
        width,
        height,
      } = this.viewRef.getBoundingClientRect();
      return callback(x, y, width, height, x, y);
    }
  };
  onLayout = () => {
    const {onLayout} = this.props;
    this.viewRef &&
      onLayout &&
      onLayout({nativeEvent: {layout: this.viewRef.getBoundingClientRect()}});
  };

  componentDidMount() {
    this.onLayout();
  }
  componentDidUpdate() {
    this.onLayout();
  }

  onContextMenu = e => {
    e && e.preventDefault && e.preventDefault();
    this.props.onLongPress && this.props.onLongPress();
    this.props.onContextMenu && this.props.onContextMenu(e);
  };

  onClick = e => {
    let {disabled, onPress} = this.props;
    if (disabled) {
      e.stopPropagation();
      return;
    }
    onPress && onPress(e);
    if (this.props.stopPropagation !== false) {
      e.stopPropagation();
    }
  };

  render() {
    let {
      children,
      style,
      getRef,
      onLayout,
      onLongPress,
      onPress,
      backgroundImage,
      ...rest
    } = this.props;
    let restProps = removeExtraDomProps(rest);
    let extraProps = {};
    if (onPress) {
      extraProps.onClick = this.onClick;
    }
    if (onLongPress) {
      extraProps.onContextMenu = this.onContextMenu;
    }
    let extraStyle = {};
    if (backgroundImage) {
      extraStyle.backgroundImage = `url(${backgroundImage})`;
    }

    if (Array.isArray(style)) {
      style = StyleSheet.flatten(style);
    }

    return (
      <div
        ref={e => {
          this.viewRef = e;
          getRef && getRef(e);
        }}
        style={{...defaultStyle, ...extraStyle, ...style}}
        {...restProps}
        {...extraProps}>
        {children}
      </div>
    );
  }
}

export default View;
