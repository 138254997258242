import Card from './src/Card';
import CardTitle from './src/CardTitle';
import WithHoverActions from './src/WithHoverActions';
import WithAction from './src/WithAction';

const CardWithAction = WithHoverActions(WithAction(Card));

export {CardWithAction as Card, CardTitle, WithHoverActions, WithAction};

export default Card;
