import React from 'react';
export const ReactModalViewListenerContext = React.createContext();
export const ReactModalViewComponentContext = React.createContext();

export const withModalContext = Component => props => {
  return (
    <ReactModalViewListenerContext.Consumer>
      {setModal => {
        return <Component setModal={setModal} {...props} />;
      }}
    </ReactModalViewListenerContext.Consumer>
  );
};
