export default (props = {}) => {
  let {action: {link} = {}, navigation} = props;
  if (typeof link === 'function') {
    link = link(props);
  }
  if (!link) {
    return;
  }
  if (link.replace) {
    navigation.replace(link);
  } else {
    navigation.push(link);
  }
};
