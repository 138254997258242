import { importDetail } from '../../Queries';
import ImportFormHoc from './ImportForm';
import { close } from '../../app-components/action/CloseAction';
import { save } from '../../app-components/action/SaveAction';
const submitMessage = 'Import Edited';
const label = 'Import Detail';
const header = {
  title: label,
  actions: [save({
    label: 'Update'
  }), close],
};
export default ImportFormHoc({ submitMessage, uri: importDetail, header });
