import React from 'react';
import {defaultTheme} from "./defaultTheme"
const show = ({title = '', text = '', duration = 2000,theme }) => {
  let message = title || text;
  let {boxStyle = {}, containerStyle = {}} = theme||defaultTheme;
  if (!message) {
    return;
  }
  const node = document.createElement('div');
  for (let index in boxStyle) {
    node.style[index] = boxStyle[index];
  }
  const containerNode = document.createElement('div');
  for (let index in containerStyle) {
    containerNode.style[index] = containerStyle[index];
  }
  duration = duration || 10 * 1000;
  containerNode.innerHTML = `${message}`;
  node.appendChild(containerNode);
  document.getElementById('root').appendChild(node);
  setTimeout(() => {
    document.getElementById('root').removeChild(node);
  }, duration);
};
export default {
  show,
  LENGTH_SHORT: 2000,
  LENGTH_LONG: 3500,
  LENGTH_INDEFINITE: 5000,
};
