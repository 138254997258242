import React, {Component} from 'react';

class ImageBackground extends Component {
  render() {
    const {source, style, children} = this.props;
    return (
      <div
        style={{
          backgroundImage: `url(${source})`,
          ...style,
        }}>
        {children}
      </div>
    );
  }
}

export default ImageBackground;
