import React from "react";
import { ReactModalViewComponentContext } from "./ReactModalViewContext";

class ReactModalView extends React.Component {
  getModal = state => {
    const { renderModal, showModal } = state;
    if (!showModal) {
      return null;
    }
    return renderModal();
  };
  render() {
    return (
      <ReactModalViewComponentContext.Consumer>
        {state => {
          const { views } = state;
          if (!views || !views.length) {
            return null;
          }
          return views.map(view => {
            const { state: viewState } = view;
            return this.getModal(viewState);
          });
        }}
      </ReactModalViewComponentContext.Consumer>
    );
  }
}

export default ReactModalView;
