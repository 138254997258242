import React from 'react';
export const AppKeyboardListenerContext = React.createContext();

export const withAppKeyboardListenerContext = Component => props => {
  return (
    <AppKeyboardListenerContext.Consumer>
      {value => {
        return <Component {...value} {...props} />;
      }}
    </AppKeyboardListenerContext.Consumer>
  );
};
