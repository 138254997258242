import {screenExpandIcon} from '../../images';

const ExpandAction = {
  icon: screenExpandIcon,
  visible: ({navigation}) => {
    const visibleViews =
      (navigation.getVisibleViews && navigation.getVisibleViews()) || 1;
    const routeCount = navigation.getRouteCount();
    if (visibleViews < 2 || routeCount < 2) {
      return false;
    }
    return true;
  },
  onPress: ({navigation}) => {
    const expanded = navigation.getExpanded && navigation.getExpanded();
    navigation.setExpanded && navigation.setExpanded(!expanded);
  },
};

export default ExpandAction;
