import React from 'react';

export default ({View, theme}) => {
  class ProgressIndicator extends React.Component {
    render() {
      let {position, color} = this.props;
      let {containerStyle, animationStyle} = theme;
      containerStyle = {
        ...containerStyle,
      };
      animationStyle = {
        ...animationStyle,
      };
      if (position) {
        containerStyle.position = position;
      }
      if (color) {
        animationStyle.backgroundColor = color;
      }
      return (
        <View style={containerStyle}>
          <View style={{...animationStyle}} />
        </View>
      );
    }
  }
  return ProgressIndicator;
};
