export const standardTheme = {
  containerStyle: {
    boxShadow:
      '0 4px 8px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19)',

    flexDirection: 'row',
    borderRadius: 10,
    padding: 8,
    alignItems: 'center',
    backgroundColor: 'white',
  },
  leftIconStyle: {
    width: 20,
    height: 20,
    marginLeft: 12,
    marginRight: 18,
  },
  messageStyle: {
    fontWeight: '500',
    fontSize: 16,
    lineHeight: 18,
    color: '#2c2c2c',
  },
  descriptionStyle: {
    paddingTop: 4,
    fontSize: 12,
    lineHeight: 14,
    color: '#8e8e8e',
  },
  actionIconStyle: {
    marginLeft: 5,
    marginRight: 5,
    width: 20,
    height: 20,
  },
  actionTextStyle: {
    marginLeft: 5,
    marginRight: 5,
    fontSize: 16,
    lineHeight: 18,
    color: '#2c2c2c',
  },
};

export const successTheme = {
  ...standardTheme,
  containerStyle: {
    boxShadow:
      '0 4px 8px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19)',

    flexDirection: 'row',
    borderRadius: 10,
    padding: 8,
    alignItems: 'center',
    backgroundColor: '#01A864',
  },
  messageStyle: {
    fontWeight: '500',
    fontSize: 16,
    lineHeight: 18,
    color: '#ffffff',
  },
  descriptionStyle: {
    paddingTop: 4,
    fontSize: 12,
    lineHeight: 14,
    color: '#ffffff',
  },
  actionTextStyle: {
    marginLeft: 5,
    marginRight: 5,

    fontWeight: '500',
    fontSize: 16,
    lineHeight: 18,
    color: '#2c2c2c',
  },
};

export const errorTheme = {
  ...standardTheme,
  containerStyle: {
    boxShadow:
      '0 4px 8px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19)',

    flexDirection: 'row',
    borderRadius: 10,
    padding: 8,
    alignItems: 'center',
    backgroundColor: '#2c2c2c',
  },
  messageStyle: {
    fontWeight: '500',
    fontSize: 16,
    lineHeight: 18,
    color: '#ffffff',
  },
  descriptionStyle: {
    paddingTop: 4,
    fontSize: 12,
    lineHeight: 14,
    color: '#8e8e8e',
  },
  actionTextStyle: {
    marginLeft: 5,
    marginRight: 5,
    fontWeight: '500',
    fontSize: 16,
    lineHeight: 18,
    color: '#ffffff',
  },
};
