import React from 'react';
export const ScreenContext = React.createContext();

export const WithScreenContext = (Component, {key}) => {
  class WithScreenContextComponent extends React.Component {
    render() {
      return (
        <ScreenContext.Consumer>
          {value => {
            const extraProps = {[key]: value};
            return <Component {...this.props} {...extraProps} />;
          }}
        </ScreenContext.Consumer>
      );
    }
  }
  return WithScreenContextComponent;
};
