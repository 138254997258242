import {Headline7, Headline8} from './Headlines';
import {manazeV5Colors} from './manazeV5Colors';

const {primary_300_main, primary_050} = manazeV5Colors;

const standardDropDownTheme = {
  dropDownContainerStyle: {
    paddingTop: 6,
    paddingBottom: 6,
  },
  dropDownItemContainerStyle: {
    paddingLeft: 16,
    paddingRight: 16,
    paddingTop: 6,
    paddingBottom: 6,
    marginTop: 4,
    marginBottom: 4,
  },
  dropDownItemIconStyle: {
    marginRight: 16,
    height: 16,
  },
  dropDownItemTextStyle: {
    ...Headline8,
    color: primary_300_main,
  },
  dropDownItemHoverContainerStyle: {backgroundColor: primary_050},
  dropDownItemHoverTextStyle: {},
  dropDownItemActiveContainerStyle: {},
  dropDownItemActiveTextStyle: {...Headline7},
  dropDownItemSelectedContainerStyle: {
    alignItems: 'center',
    justifyContent: 'center',
    marginRight: 6,
  },
  dropDownItemSelectedIconStyle: {},
};

export {standardDropDownTheme};
