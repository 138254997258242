import React from 'react';

class NumberInput extends React.Component {
  onChange = e => {
    let {setValue, field, data, dataUpdate, typecast} = this.props;
    let text = e.target.value;
    let value = void 0;
    if (typeof text === 'string') {
      value = text.trim();
      if (value.length) {
        value = Number(text);
      } else {
        value = void 0;
      }
    }
    if (typecast) {
      value = typecast(value);
    }
    if (dataUpdate) {
      setValue = dataUpdate.setValue;
      data = dataUpdate.data;
    }
    if (setValue) {
      if (field && data) {
        setValue({field, value, event: e});
      } else {
        setValue({value, event: e});
      }
    }
  };

  onFocus = e => {
    let {setFocus, dataUpdate, field} = this.props;

    if (dataUpdate) {
      setFocus = dataUpdate.setFocus;
    }

    if (setFocus && field) {
      setFocus({field, focus: true});
    }
  };
  onBlur = e => {
    let {setFocus, dataUpdate, field} = this.props;
    if (setFocus && field) {
      setFocus({field, focus: false});
    } else if (dataUpdate) {
      dataUpdate.setFocus({field, focus: false});
    }
  };
  onKeyUp = e => {
    let {onKeyUp, onSubmitEditing} = this.props;
    const keyCode = e.keyCode;
    if (keyCode === 13) {
      onSubmitEditing && onSubmitEditing(e);
    }
    onKeyUp && onKeyUp(e);
  };

  render() {
    let {
      value,
      dataUpdate,
      data,
      field,
      onChangeValue,
      editable,
      onSubmitEditing,
      getRef,
      style,
      theme,
      ...restProps
    } = this.props;
    if (dataUpdate) {
      data = dataUpdate.data;
    }

    if (data && field) {
      value = data[field];
    }
    if (value === undefined || value === null) {
      value = '';
    }

    let extraProps = {type: 'number'};
    if (editable === false) {
      extraProps['disabled'] = true;
    }
    if (getRef) {
      extraProps.ref = getRef;
    }
    if (onSubmitEditing) {
      extraProps['onKeyUp'] = this.onKeyUp;
    }
    return (
      <input
        style={theme || style}
        {...restProps}
        {...extraProps}
        value={value}
        onChange={this.onChange}
        onFocus={this.onFocus}
        onBlur={this.onBlur}
        keyboardType={'numeric'}
      />
    );
  }
}
export default NumberInput;
