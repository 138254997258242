const AsyncStorage = {
  setItem(key, value) {
    return Promise.resolve(localStorage.setItem(key, value));
  },
  getItem(key) {
    let value = localStorage.getItem(key);

    return Promise.resolve(value);
  },
  clear() {
    return Promise.resolve(localStorage.clear());
  },
  removeItem(...args) {
    return Promise.resolve(localStorage.removeItem(...args));
  },
  multiGet(args) {
    let argsValues = [];
    args &&
      args.forEach(key => {
        argsValues.push(AsyncStorage.getItem(key).then(value => [key, value]));
      });
    return Promise.all(argsValues);
  },
};

export default AsyncStorage;
