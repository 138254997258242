export const filterBarTheme = {
  fixedContainerStyle: {
    justifyContent: 'flex-end',
    height: 56,
    paddingLeft: 12,
    paddingRight: 12,
    backgroundColor: '#FAFAFA',
  },
  absoluteContainerStyle: {
    zIndex: 1,
    position: 'absolute',
    top: 0,
    right: 0,
    height: 56,
    paddingLeft: 12,
    paddingRight: 12,
  },
  bottomContainerStyle: {
    top: void 0,
    bottom: 0,
  },
};
