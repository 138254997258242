import React from 'react';
import {ScreenContext} from './ScreenContext';

export class ScreenContextProvider extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      setScreenContextValue: this.setScreenContextValue,
      filters: {},
    };
  }
  setScreenContextValue = state => {
    this.setState(state);
  };
  render() {
    const {children} = this.props;
    return (
      <ScreenContext.Provider value={this.state}>
        {children}
      </ScreenContext.Provider>
    );
  }
}
