const manazeV5Colors = {
    primary_900: '#002ca7', // 'rgb 0 44 167'
    primary_800: '#003fbe', // 'rgb 0 63 190'
    primary_700: '#004aca', // 'rgb 0 74 202'
    primary_500: '#005de2', // 'rgb 0 93 226'
    primary_600: '#0054d7', // 'rgb 0 84 215'
    primary_400: '#4276ec', // 'rgb 66 118 236'
    primary_300_main: '#6d91f0', // 'rgb 109 145 240'
    primary_200: '#9bb0f4', // 'rgb 155 176 244'
    primary_100: '#c4cff8', // 'rgb 196 207 248'
    primary_050: '#e8ecfc', // 'rgb 232 236 252'
    primary_000: '#f2f5ff', // 'rgb 242 245 255'
    secondary_900: '#006c37', // 'rgb 0 108 55'
    secondary_800: '#008c4e', // 'rgb 0 140 78'
    secondary_700: '#009e5b', // 'rgb 0 158 91'
    secondary_600: '#00b169', // 'rgb 0 177 105'
    secondary_500: '#00c175', // 'rgb 0 193 117'
    secondary_400: '#00cc89',  // 'rgb 0 204 137'
    secondary_300_main: '#1ed69e', // 'rgb 30 214 158'
    secondary_200: '#7de1bb', // 'rgb 125 225 187'
    secondary_100: '#b4edd5', // 'rgb 180 237 213'
    secondary_050: '#e0f8ee', // 'rgb 224 248 238'
    neutral_900: '#242424', // 'rgb 36 36 36'
    neutral_800_high_background: '#454545', // 'rgb 69 69 69'
    neutral_700_high_surface: '#656565', // 'rgb 101 101 101'
    neutral_600_medium_background: '#797979', // 'rgb 121 121 121'
    neutral_500_medium_surface: '#a2a2a2', // 'rgb 162 162 162' 
    neutral_400_low_background: '#c1c1c1', // 'rgb 193 193 193'
    neutral_300_low_surface: '#e3e3e3', // 'rgb 227 227 227'
    neutral_200: '#f0f0f0', // 'rgb 240 240 240'
    neutral_100_background: '#f6f6f6', // 'rgb 246 246 246'
    neutral_50: '#fbfbfb', // 'rgb 251 251 251'
    Surface: '#ffffff', // 'rgb 255 255 255'
    NegativeAction_900: '#d7302a', // 'rgb 215 48 42' 
    NegativeAction_800: '#e63b36', // 'rgb 230 59 54'
    NegativeAction_700: '#f3423d', // 'rgb 243 66 61'
    NegativeAction_600: '#ff4c44', // 'rgb 255 76 68'
    NegativeAction_500: '#ff5343', // 'rgb 255 83 67'
    NegativeAction_400: '#ff535e', // 'rgb 255 83 94'
    NegativeAction_300: '#ff8181', // 'rgb 255 129 129'
    NegativeAction_200: '#ffa5a5', // 'rgb 255 165 165'
    NegativeAction_100: '#ffd4d9', // 'rgb 255 212 217'
    NegativeAction_050: '#ffeef1', // 'rgb 255 238 241'
    Warning_WIP_900: '#fe7f00', // 'rgb 254 127 0'
    Warning_WIP_800: '#ffab00', // 'rgb 255 171 0'
    Warning_WIP_700: '#ffc30f', // 'rgb 255 195 15'
    Warning_WIP_600: '#ffdc1c', // 'rgb 255 220 28'
    Warning_WIP_500: '#f8e814', // 'rgb 248 232 20'
    Warning_WIP_400: '#fded46', // 'rgb 253 237 70'
    Warning_WIP_300: '#fdf16b', // 'rgb 253 241 107'
    very_light_blue: '#e8eeff', // 'rgb 232 238 255'
}

export {manazeV5Colors}